<template>
  <div class="animated fadeIn">
    <!--<loading v-if="vLoading" />-->
    <v-col cols="12">
      <v-card class="pa-2">
        <v-row class="time-row">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <span id="activityDateFrom2">
              <v-text-field
                outlined
                dense
                type="text"
                append-icon="calendar_today"
                v-model="dateFrom"
                label=" تاریخ از "
                :editable="true"
                class="date-input"
              >
              </v-text-field>
            </span>

            <date-picker
              v-model="dateFrom"
              element="activityDateFrom2"
              color="#00a7b7"
            /> </v-col
          ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <span id="activityDateTo2">
              <v-text-field
                outlined
                dense
                type="text"
                append-icon="calendar_today"
                v-model="dateTo"
                label=" تاریخ تا "
                :editable="true"
                class="date-input"
              >
              </v-text-field>
            </span>

            <date-picker
              v-model="dateTo"
              element="activityDateTo2"
              color="#00a7b7"
            />
          </v-col>
          <v-row class="mb-4 mx-1">
            <v-col>
              <v-btn
                class="submit-btn primary-btn"
                style="float: right"
                @click="getUserActivities()"
                :disabled="activityBusy"
                >اعمال</v-btn
              >
            </v-col>
          </v-row>
        </v-row>
        <v-text-field
          v-model="Filter"
          prepend-inner-icon="mdi-magnify"
          label="جستجو"
          single-line
          hide-details
          clearable
          rounded
          filled
          class="text-right w-80 mb-2 search-input"
        ></v-text-field>
        <b-table
          responsive
          show-empty
          :fields="Fields"
          :items="userActivities"
          empty-text="در بازه زمانی انتخاب شده خدمتی برای نمایش وجود ندارد"
          empty-filtered-text="در بازه زمانی انتخاب شده خدمتی برای نمایش وجود ندارد"
          :busy="activityBusy"
          :filter="Filter"
          :current-page="CurrentPage"
          :per-page="PerPage"
          @filtered="onFiltered"
        >
          <template v-slot:head()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(index)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
            </div>
          </template>
          <template v-slot:cell(operation)="data">
            <div style="text-align: center; vertical-align: middle">
              <v-btn class="primary-btn" @click="gotoEditService(data.item)"
                ><v-icon> edit</v-icon></v-btn
              >
            </div>
          </template>
          <div slot="table-busy" class="text-center primary--text my-2">
            <v-progress-circular
              indeterminate
              color="primary"
              class="align-middle"
            ></v-progress-circular>
          </div>
        </b-table>
        <v-pagination
          v-model="CurrentPage"
          :length="Math.ceil(TotalRows / PerPage)"
          :total-visible="5"
          prev-icon="arrow_back"
          next-icon="arrow_forward"
        ></v-pagination>
        <v-select
          label="تعداد در هر صفحه:"
          style="width: 150px"
          v-model="PerPage"
          :items="perPageOptions"
          item-text="text"
          item-value="value"
        ></v-select>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  props: ["userId"],
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      drName: "",
      nurseName: "",
      Filter: "",
      activityBusy: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "serviceName", label: "خدمت" },
        { key: "date", label: "تاریخ" },
      ],
      Items: [],
      now: "",
      dateFrom: moment(new Date()).subtract(3, "day").format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",

      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
      requestId: "",

      showAll: false,
      deviceType: "",
      userActivities: [],
    };
  },

  methods: {
    getUserActivities() {
      //NOTE getting servant's acrivity info
      this.activityBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employee/activities",
          {
            userId: this.userId,
            startDate: this.dateFrom,
            endDate: this.dateTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.userActivities = res.data;
            this.TotalRows = this.userActivities.length;
            this.CurrentPage = 1;
            this.activityBusy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.activityBusy = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.activityBusy = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },

  mounted() {
    this.getUserActivities();
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");
  },
};
</script>
